import * as React from "react";
import {BrowserRouter, createBrowserRouter, Route, RouterProvider} from "react-router-dom";
import LandingResidential from "./LandingResidential";
import {useEffect} from 'react';

import SelectFranchise from "./SelectFranchise";
import {GetFranchiseByName} from "../Api";
import ReactGA from "react-ga4";
import Booking from "./Booking";
import {CssVarsProvider, extendTheme} from "@mui/joy";
import LandingCommercial from "./LandingCommercial";

const theme = extendTheme({
    colorSchemes: {
        light: {
            palette: {
                primary: {
                    50: '#001D83',
                    100: '#001D83',
                    200: '#001D83',
                    300: '#001D83',
                    400: '#001D83',
                    500: '#001D83',
                    600: '#001D83',
                    700: '#001D83',
                    800: '#001D83',
                    900: '#001D83',
                },
            },
        },
    },
});

export default function App() {
    useEffect(() => {
        ReactGA.initialize([
            {
                trackingId: 'AW-16693850742',
            }
        ]);
    }, []);

    const router = createBrowserRouter([
        {
            element: <LandingResidential/>,
            path: ":service?/:franchise?",
            loader: async ({request, params}) => {
                if (params.franchise) {
                    let [franchise] = await Promise.all([
                        GetFranchiseByName(`${params.franchise}`).then(res => res as Franchise),
                    ]);
                    return {franchise}
                }
                return null

            }
        },

        {
            element: <LandingCommercial/>,
            path: "commercial/:franchise?",
            loader: async ({request, params}) => {
                if (params.franchise) {
                    let [franchise] = await Promise.all([
                        GetFranchiseByName(`${params.franchise}`).then(res => res as Franchise),
                    ]);
                    return {franchise}
                }
                return null

            }
        },
        {
            element: <Booking/>,
            path: "/new-booking",
        },
    ]);

    return (

        <>
            <CssVarsProvider theme={theme}>
                <RouterProvider router={router}/>
            </CssVarsProvider>

        </>
    )
}