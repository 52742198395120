import * as React from "react";
import {useEffect, useState} from "react";
import {useLoaderData, useNavigate, useParams} from "react-router-dom";
import banner from './images/header-commercial.jpg';
import cta1 from './images/CTA/cta-1.png';
import cta2 from './images/CTA/cta-2.png';
import cta3 from './images/CTA/cta-3.png';
import cta4 from './images/CTA/cta-4.png';
import cta5 from './images/CTA/cta-5.png';
import windowClean from './images/window_clean.jpg';
import trustPilotVert from './images/trust-pilot-vert.png';
import logo from './images/shw-logo-transparent.png';
import logo_white from './images/shw_logo_white.png';
import ukMap from './images/uk-map.png';

import adam_church from './images/companies/adam_church.png';
import bns from './images/companies/bns.png';
import denfords from './images/companies/denfords.png';
import drive from './images/companies/drive.png';
import hml from './images/companies/hml.png';
import housing_21 from './images/companies/housing_21.png';
import john_west_contractors from './images/companies/john_west_contractors.png';
import pure from './images/companies/pure-gym.png';
import salisbury from './images/companies/salisbury-playhouse.png';
import the_hive from './images/companies/the_hive.png';
import lsh from './images/companies/lsh.jpg';
import bark from './images/companies/bark_cert.png';
import trustPilot from './images/companies/trustpilot.png';
import trustPilotWhite from './images/companies/trustpilot_white.png';


import Option from "@mui/joy/Option";
import {
    Box,
    Button,
    Card,
    CardOverflow,
    FormHelperText,
    FormLabel,
    Grid,
    Input, Link,
    Select,
    Sheet, Textarea,
    Tooltip,
    Typography,
    CardContent,
    AspectRatio
} from "@mui/joy";
import {
    ArrowRight, ArrowRightAlt,
    CheckCircle,
    CleaningServices,
    Done,
    Email, Group,
    Home,
    HomeRepairService, LocationOn, MilitaryTech, NextPlan,
    Phone,
    Star, SupportAgent,
    TrendingFlat, Verified
} from "@mui/icons-material";
import {GetAddressFromPostcode, SubmitCommercialLeadForm} from "../Api";
import ListItem from "@mui/joy/ListItem";
import List from "@mui/joy/List";
import {ListItemAvatar, ListItemText} from "@mui/material";

interface AddressResponse {
    suggestions: Address[];
}

interface Address {
    address: string;
}


export default function LandingCommercial() {
    const {franchiseName} = useParams();
    const loadedData = useLoaderData() as LoadedData;
    const [franchise, setFranchise] = useState<Franchise>(loadedData?.franchise);
    const [lead, setLead] = useState<CommercialLead>({
        address: "",
        eMail: "",
        propertyType: "",
        name: "",
        phone: "",
        postcode: "",
        notes: "",
        serviceType: ['Window Clean', 'Gutter Clean']
    });
    const [addressList, setAddressList] = useState<Address[]>([]);
    const [validPostcode, setValidPostcode] = useState<boolean>(true);
    const [submittedForm, setSubmittedForm] = useState<boolean>(false);
    const [debouncedPostcode, setDebouncedPostcode] = useState(lead.postcode);
    const navigate = useNavigate();

    const logos = [
        adam_church, bns, denfords, drive, hml, housing_21, john_west_contractors, pure, salisbury, the_hive, lsh
    ];

    const certificates = [
        bark, trustPilot
    ];


    useEffect(() => {
        if (lead.postcode == "") {
            return;
        }
        const handler = setTimeout(() => {
            setDebouncedPostcode(lead.postcode);
            const getAddresses = async () => {
                let addresses: AddressResponse = await GetAddressFromPostcode(lead.postcode) as AddressResponse;
                setAddressList(addresses.suggestions)

                if (addresses.suggestions.length > 0) {
                    setValidPostcode(true)
                } else {
                    setValidPostcode(false)
                }

            };
            getAddresses();


        }, 750);


        return () => {
            clearTimeout(handler);
        };

    }, [lead.postcode]);


    const scrollToForm = () => {
        const formElement = document.getElementById('form-submit');
        if (formElement) {
            formElement.scrollIntoView({behavior: 'auto', block: 'center'});
        }
    };

    useEffect(() => {
        if (franchise) {
            document.title = 'Get A Quote | ' + franchise.location;
        }
    }, [franchise]);

    function saveLead() {
        sendQuoteConversion()
        saveForm()
    }

    function saveForm() {
        const submit = async () => {
            await SubmitCommercialLeadForm(lead);
            setSubmittedForm(true)
        }
        submit()
    }

    function sendQuoteConversion() {
        let value = 0;
        window.gtag('event', 'conversion', {
            'send_to': 'AW-16693850742/ltNGCIL0l-EZEPbkn5g-',
            'value': value,
            'currency': 'GBP'
        });

    }


    return (
        <>
            <Box sx={(theme) => ({
                display: 'flex',
                height: '75px',
                maxWidth: '1200px',
                marginLeft: 'auto',
                marginRight: 'auto',
                flexDirection: 'column',
                [theme.breakpoints.down('md')]: {
                    width: '100%',
                }
            })}>

                <Box sx={(theme) => ({
                    display: 'flex',
                    width: '100%',
                    zIndex: 10000,
                    justifyContent: 'space-between',
                    boxSizing: 'border-box',
                    paddingLeft: '50px',
                    paddingRight: '50px',
                    [theme.breakpoints.down('md')]: {
                        paddingLeft: '0px',
                        paddingRight: '0px'
                    },
                    [theme.breakpoints.down('sm')]: {
                        marginBottom: '15px',
                        justifyContent: 'center',
                        flexDirection: 'column-reverse',
                        padding: '0',
                        alignItems: 'center'
                    },

                })}>
                    <Box sx={(theme) => ({
                        padding: '25px',
                        paddingTop: '10px',
                        [theme.breakpoints.down('md')]: {
                            paddingLeft: '0px',
                            paddingRight: '0px'
                        },
                        [theme.breakpoints.down('sm')]: {
                            padding: '0',
                            margin: '20px',
                            width: 'fit-content'
                        },
                    })}>
                        <img style={{"height": "75px"}} src={logo} alt="Sky High Window Cleaning Logo"/>
                    </Box>
                    <Box sx={(theme) => ({
                        display: 'flex',
                        flexDirection: 'column',
                        textAlign: 'center',
                        height: '45%',
                        padding: '25px',
                        marginRight: '25px',
                        background: '#001D83',
                        justifyContent: 'end',
                        marginTop: '-5px',
                        [theme.breakpoints.down('md')]: {
                            marginRight: '0px',
                            alignItems: 'center',
                            boxSizing: 'border-box'
                        },
                        [theme.breakpoints.down('sm')]: {
                            width: '100%'
                        },

                    })}>
                        <Typography sx={{margin: 0, color: 'white'}} level="title-sm">Get In Touch</Typography>
                        <Typography sx={{margin: 0, color: 'white', fontSize: '24px'}} level="title-lg"
                                    startDecorator={<Phone sx={{color: "white"}}/>}>07914
                            456 742</Typography>
                    </Box>
                </Box>

                <Box
                    sx={(theme) => ({
                        marginTop: '-115px',
                        display: 'flex', width: '100%', minHeight: '700px',
                        backgroundImage: ` linear-gradient(100deg, rgba(0,0,0,0.75) 0%, rgba(255,255,255,0) 100%), url(${banner})`,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        backgroundPosition: 'center',
                        [theme.breakpoints.down('md')]: {
                            minHeight: '150vw',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            background: `radial-gradient(circle, 
                 rgba(0,0,0,0.283) 0%, 
                 rgba(158,158,158,0) 100%), 
                 linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), 
                 url(${banner})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            textAlign: 'center',
                            overflow: 'hidden',
                        },
                        [theme.breakpoints.down('sm')]: {
                            marginTop: '-266px',
                            minHeight: '160vh',
                        },
                    })}
                >
                    <Box sx={(theme) => ({
                        display: 'flex',
                        flexDirection: 'column',
                        marginLeft: '50px',
                        width: '900px',
                        height: '100%',
                        justifyContent: 'center',
                        [theme.breakpoints.down('md')]: {
                            width: 'auto',
                            marginLeft: '10px',
                            marginRight: '10px',
                            height: 'auto',
                        }
                    })}>
                        {franchise ? (
                            <Typography
                                sx={(theme) => ({
                                    height: 'fit-content',
                                    color: "white",
                                    textShadow: '2px 2px 2px rgba(0,0,0,0.1)',
                                    paddingTop: '15px',
                                    paddingBottom: '10px',
                                    [theme.breakpoints.down('md')]: {
                                        fontSize: '30px',
                                        width: '95%',
                                        marginBottom: '15px',
                                        textAlign: 'center'
                                    },
                                    [theme.breakpoints.down('sm')]: {
                                        width: '95vw'
                                    }
                                })}
                                level={"h1"}>{`${franchise.location}`} Commercial Exterior Cleaning
                                Services</Typography>

                        ) : (
                            <Typography
                                sx={(theme) => ({
                                    height: 'fit-content',
                                    color: "white",
                                    textShadow: '2px 2px 2px rgba(0,0,0,0.1)',
                                    paddingTop: '15px',
                                    paddingBottom: '10px',
                                    [theme.breakpoints.down('md')]: {
                                        fontSize: '34px',
                                        width: '95%',
                                        marginBottom: '15px',
                                        textAlign: 'center'
                                    },
                                    [theme.breakpoints.down('sm')]: {
                                        width: '90vw',
                                        fontSize: '28px',
                                    }
                                })}
                                level={"h1"}>Nationwide Commercial Exterior Cleaning Services</Typography>
                        )}

                        <Box>
                            <List sx={{padding: 0}}>
                                {['Roof Cleaning', 'Gutter Cleaning', 'Window Cleaning', 'Pressure Washing', 'Cleaning Render'].map((service, index) => (
                                    <ListItem key={index} sx={{
                                        padding: 0,
                                        marginBottom: '5px'
                                    }}>
                                        <ListItemAvatar sx={{alignItems: 'center', display: 'flex', minWidth: '0px'}}>
                                            <CheckCircle sx={(theme) => ({
                                                color: 'green', fontSize: '32px', margin: 0,
                                                [theme.breakpoints.down('md')]: {
                                                    fontSize: '42px',
                                                    color: '#1dff00'
                                                },
                                                [theme.breakpoints.down('sm')]: {
                                                    fontSize: '38px',
                                                }
                                            })}/>
                                        </ListItemAvatar>
                                        <ListItemText sx={(theme) => ({
                                            color: 'white', fontSize: '18px',
                                            [theme.breakpoints.down('md')]: {
                                                width: 'fit-content'
                                            },
                                        })}>
                                            <Typography level={"h3"} sx={(theme) => ({
                                                color: 'white !important',
                                                fontWeight: '500 !important',
                                                [theme.breakpoints.down('md')]: {
                                                    width: 'fit-content'
                                                },
                                                [theme.breakpoints.down('sm')]: {
                                                    fontSize: '18px',
                                                }
                                            })}>
                                                {service}
                                            </Typography>

                                        </ListItemText>
                                    </ListItem>
                                ))}
                                <ListItem sx={(theme) => ({
                                    padding: 0,
                                    [theme.breakpoints.down('md')]: {
                                        marginTop: '25px',
                                    },
                                })}>
                                    <Button onClick={(event) => {
                                        scrollToForm()
                                    }} endDecorator={<ArrowRightAlt/>} variant="solid" size={"lg"}
                                            sx={(theme) => ({
                                                width: '100%',
                                                [theme.breakpoints.down('md')]: {
                                                    fontSize: '16px'
                                                },
                                            })}>Click Here To Get Your
                                        Quote</Button>
                                </ListItem>
                            </List>
                        </Box>
                    </Box>
                    <Box sx={(theme) => ({
                        display: 'flex',
                        width: '100%',
                        justifyContent: 'flex-end',
                        margin: '25px',
                        height: 'fit-content',
                        marginTop: 'auto',
                        [theme.breakpoints.down('md')]: {
                            width: 'fit-content',
                            marginTop: '50px'
                        },
                        [theme.breakpoints.down('sm')]: {
                            flexDirection: 'column-reverse',
                            alignItems: 'center'
                        }
                    })}>
                        <Box
                            component="img"
                            src={bark}
                            sx={(theme) => ({
                                width: 'auto', height: '70px', borderRadius: '8px', mr: 3,
                                [theme.breakpoints.down('sm')]: {
                                    width: '33%',
                                    height: 'auto',
                                }
                            })}
                        />
                        <Box
                            component="img"
                            src={trustPilotWhite}
                            sx={(theme) => ({
                                width: 'auto', height: '70px', borderRadius: '8px',
                                [theme.breakpoints.down('sm')]: {
                                    width: '85%',
                                    height: 'auto',
                                    marginBottom: '15px'
                                }
                            })}
                        />
                    </Box>
                </Box>


                <Box sx={(theme) => ({
                    width: '100%', height: '150px', background: '#0B6BCB', [theme.breakpoints.down('md')]: {
                        display: 'none'
                    },
                })}>
                    <Box sx={(theme) => ({
                        display: 'flex',
                        justifyContent: 'space-between',
                        height: '150px',
                        alignItems: 'center',
                        boxSizing: 'border-box',
                        [theme.breakpoints.down('md')]: {
                            height: 'auto',
                            flexDirection: 'column',
                            marginTop: '10px',
                            marginBottom: '10px'
                        },
                    })}>
                        <Box sx={{
                            display: 'flex',
                            width: '20%',
                            height: '100%',
                            alignItems: 'center',
                            justifyContent: 'center',
                            background: '#001D83'
                        }}>
                            <img style={{height: '120px'}} src={cta1}
                                 alt="Sky High Certified Gutter Cleaning"/>
                        </Box>
                        <Box sx={{
                            display: 'flex',
                            width: '80%',
                            height: '100%',
                            background: '#F2F9FF',
                            justifyContent: 'space-around',
                            alignItems: 'center',
                        }}>

                            <img style={{height: '120px'}} src={cta2}
                                 alt="Protect Against Insurance Claims"/>
                            <img style={{height: '120px'}} src={cta3}
                                 alt="Timestamp Certified for up to 6 months"/>
                            <img style={{height: '120px'}} src={cta4}
                                 alt="Full insuranced and trained professionals"/>
                            <img style={{height: '120px'}} src={cta5}
                                 alt="100% Satisfaction Guranteed or you don't pay"/>
                        </Box>

                    </Box>
                </Box>
                <Sheet id={"booking-form"} color="neutral" sx={(theme) => ({
                    display: 'flex',
                    width: '100%',
                    background: '#F2F9FF',
                    [theme.breakpoints.down('md')]: {
                        flexDirection: 'column'
                    },
                })}>
                    <Box sx={(theme) => ({
                        display: 'flex',
                        width: '50%',
                        flexDirection: 'column',
                        alignItems: 'normal',
                        height: '100%',
                        background: 'white',
                        padding: '40px',
                        paddingBottom: '0px',
                        boxSizing: 'border-box',
                        [theme.breakpoints.down('md')]: {
                            width: '100%',
                            order: 3,
                            padding: '15px',
                            marginTop: '15px'
                        },
                    })}>

                        <Typography level={"h2"} color={"primary"} fontWeight={750}
                                    sx={{marginBottom: '5px', textAlign: 'center', marginBottom: '2px'}}>Why
                            Choose Sky High</Typography>
                        <Typography sx={{textAlign: 'center', fontSize: '18px', marginTop: 0}} fontWeight={500}
                                    color={"neutral"}>For
                            your commercial
                            property</Typography>


                        <Typography sx={{marginBottom: '10px', marginTop: '15px'}}>For 17 years, Sky
                            High has been setting the gold standard in
                            commercial window and gutter cleaning across the UK. With 23 branches
                            nationwide, we're your local experts with a national reach.
                        </Typography>
                        <Typography sx={{marginBottom: '10px'}}>Property managers and business owners trust us to
                            maintain the pristine
                            appearance of their buildings, enhancing curb appeal and protecting their
                            investments.</Typography>
                        <Box>
                            <Typography color={"primary"} fontWeight={750}
                                        startDecorator={<CleaningServices sx={{width: '30px', marginRight: '5px'}}/>}
                                        sx={{mb: 2, mt: 3}}>
                                Unmatched Expertise: 17 years of industry-leading experience
                            </Typography>

                            <Typography color={"primary"} fontWeight={750}
                                        startDecorator={<LocationOn sx={{width: '30px', marginRight: '5px'}}/>}
                                        sx={{mb: 2}}>
                                Nationwide Coverage: 23 branches across the UK for prompt, local service
                            </Typography>

                            <Typography color={"primary"} fontWeight={750}
                                        startDecorator={<Verified sx={{width: '30px', marginRight: '5px'}}/>}
                                        sx={{mb: 2}}>
                                Fully Accredited with Comprehensive Insurance: Your property is in safe hands £10
                                million Public Liability
                            </Typography>

                            <Typography color={"primary"} fontWeight={750}
                                        startDecorator={<MilitaryTech sx={{width: '30px', marginRight: '5px'}}/>}
                                        sx={{mb: 2}}>
                                Cutting-Edge Technology: State-of-the-art equipment for superior results
                            </Typography>

                            <Typography color={"primary"} fontWeight={750}
                                        startDecorator={<Group sx={{width: '30px', marginRight: '5px'}}/>}
                                        sx={{mb: 2}}>
                                Trained Professionals: Our team is skilled, vetted, and customer-focused
                            </Typography>

                            <Typography color={"primary"} fontWeight={750}
                                        startDecorator={<NextPlan sx={{width: '30px', marginRight: '5px'}}/>}
                                        sx={{mb: 2}}>
                                Tailored Solutions: Customised cleaning plans for your unique needs
                            </Typography>

                            <Typography color={"primary"} fontWeight={750}
                                        startDecorator={<SupportAgent sx={{width: '30px', marginRight: '5px'}}/>}
                                        sx={{mb: 2}}>
                                Dedicated Customer Service: Experience personalised care with our dedicated support
                                team, ready to assist you with any inquiries or service requests.
                            </Typography>

                        </Box>
                        <Typography sx={{marginBottom: '10px', fontWeight: 500}}>Contact Sky High External Cleaning
                            today for a free,
                            no-obligation quote. Discover
                            why we're the preferred choice for commercial property managers and businesses
                            across the UK.</Typography>
                    </Box>

                    <form class="form-width" id="form-submit" onSubmit={(event) => {
                        event.preventDefault();
                        saveLead()
                    }}>
                        {submittedForm ? (
                            <>
                                <Box sx={{
                                    height: '600px',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    display: 'flex',
                                    flexDirection: 'column'
                                }}>
                                    <CheckCircle sx={{fontSize: '60px'}} color={"success"}/>
                                    <Typography level={"h2"} color={"success"}>Successfully Submitted
                                        Message</Typography>
                                    <Typography level={"h3"} color={"neutral"}>We will be in contact
                                        shortly.</Typography>
                                </Box>
                            </>
                        ) : (
                            <>

                                <Box sx={(theme) => ({
                                    display: 'flex',
                                    flexDirection: 'column',
                                    width: '550px',
                                    height: '100%',
                                    maxWidth: '550px',
                                    [theme.breakpoints.down('md')]: {
                                        width: '100%',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    },
                                })}>
                                    <Typography color={"primary"} level={'h2'} sx={{textAlign: 'center', mt: 3, mb: 3}}>Get
                                        A
                                        Quote For Your Property</Typography>
                                    <Box sx={(theme) => ({
                                        width: '100%', margin: '10px',
                                        [theme.breakpoints.down('md')]: {
                                            width: '90%',
                                        },
                                    })}>
                                        <FormLabel>Full Name</FormLabel>
                                        <Input required onChange={(event) => {
                                            setLead(prevLead => ({
                                                ...prevLead,
                                                name: event.target.value,
                                            }))
                                        }}
                                               sx={{width: '100%', background: 'white'}}
                                               placeholder="Full Name"></Input>
                                    </Box>
                                    <Box sx={(theme) => ({
                                        display: 'flex', flexDirection: 'row', width: '100%', margin: '10px',
                                        [theme.breakpoints.down('md')]: {
                                            width: '90%',
                                            flexDirection: 'column'
                                        },
                                    })}>
                                        <Box sx={{width: '100%', marginRight: '10px'}}>
                                            <FormLabel>E-Mail Address</FormLabel>
                                            <Input required startDecorator={<Email/>} onChange={(event) => {
                                                setLead(prevLead => ({
                                                    ...prevLead,
                                                    eMail: event.target.value,
                                                }));
                                            }} sx={{background: 'white'}} placeholder="Email"></Input>
                                        </Box>
                                        <Box sx={(theme) => ({
                                            width: '100%',
                                            [theme.breakpoints.down('md')]: {
                                                marginTop: '20px'
                                            }
                                        })}>
                                            <FormLabel>Phone Number</FormLabel>

                                            <Input value={lead.phone} startDecorator={<Phone/>} onChange={(event) => {
                                                setLead(prevLead => ({
                                                    ...prevLead,
                                                    phone: event.target.value,
                                                }))
                                            }} sx={{background: 'white'}} placeholder="Phone Number"></Input>
                                        </Box>
                                    </Box>
                                    <Box sx={(theme) => ({
                                        width: '100%', margin: '10px',
                                        [theme.breakpoints.down('md')]: {
                                            width: '90%',
                                        },
                                    })}>
                                        <FormLabel>Property Type</FormLabel>
                                        <Input required value={lead.propertyType}
                                               inputProps={{style: {textTransform: "uppercase"}}}
                                               onChange={(event) => {
                                                   setLead(prevLead => ({
                                                       ...prevLead,
                                                       propertyType: event.target.value.toUpperCase()
                                                   }));
                                               }} sx={{background: 'white'}} placeholder="Property Type"></Input>
                                    </Box>
                                    <Box sx={(theme) => ({
                                        width: '100%', margin: '10px',
                                        [theme.breakpoints.down('md')]: {
                                            width: '90%',
                                        },
                                    })}>
                                        <FormLabel>Requested Service</FormLabel>
                                        <Select
                                            value={lead.serviceType}
                                            multiple
                                            required
                                            sx={{background: 'white'}}
                                            startDecorator={<HomeRepairService/>}
                                            onChange={(e, newValue) => {
                                                setLead(prevLead => ({
                                                    ...prevLead,
                                                    serviceType: newValue,
                                                }))
                                            }}
                                        >
                                            <Option value={"Window Clean"}>Window Cleaning</Option>
                                            <Option value={"Gutter Clean"}>Gutter Cleaning</Option>
                                            <Option value={"Roof Clean"}>Roof Cleaning</Option>
                                            <Option value={"Pressure Washing"}>Pressure Washing</Option>
                                            <Option value={"Cleaning Render"}>Cleaning Render</Option>
                                        </Select>
                                    </Box>

                                    <Box sx={(theme) => ({
                                        width: '100%', margin: '10px',
                                        [theme.breakpoints.down('md')]: {
                                            width: '90%',
                                        },
                                    })}>
                                        <FormLabel>Postcode</FormLabel>
                                        <Input required value={lead.postcode}
                                               inputProps={{style: {textTransform: "uppercase"}}}
                                               onChange={(event) => {
                                                   setLead(prevLead => ({
                                                       ...prevLead,
                                                       postcode: event.target.value.toUpperCase()
                                                   }));
                                               }} sx={{background: 'white'}} placeholder="Postcode"></Input>

                                        {!validPostcode &&
                                            <FormHelperText>
                                                <Typography color="danger">Please enter a valid postcode!</Typography>
                                            </FormHelperText>
                                        }

                                    </Box>
                                    <Tooltip
                                        title={addressList?.length > 0 ? "" : "Please enter a valid postcode!"}
                                        arrow placement="bottom" variant="solid"
                                        color="danger">
                                        <Box sx={(theme) => ({
                                            width: '100%', margin: '10px', maxWidth: '550px',
                                            [theme.breakpoints.down('md')]: {
                                                width: '90%',
                                            },
                                        })}>
                                            <FormLabel>Address</FormLabel>
                                            <Select
                                                disabled={addressList?.length == 0}
                                                required
                                                slotProps={{
                                                    listbox: {
                                                        sx: {
                                                            maxWidth: '550px', minWidth: '550px',
                                                            "> li:nth-child(odd)": {
                                                                background: "rgba(240,244,248,0.6)",
                                                            },
                                                        },
                                                    },
                                                }}
                                                onChange={(e, newValue) => {
                                                    setLead(prevLead => ({
                                                        ...prevLead,
                                                        address: newValue,
                                                    }))
                                                }}
                                                placeholder="Select address..."
                                                sx={{background: 'white', maxWidth: '550px'}}
                                                startDecorator={<Home/>}
                                            >
                                                {addressList?.map((address: Address, index: number) => (
                                                    <Option value={address.address}>{address.address}</Option>
                                                ))}

                                            </Select>
                                        </Box>
                                    </Tooltip>
                                    <Box sx={(theme) => ({
                                        width: '100%', margin: '10px',
                                        [theme.breakpoints.down('md')]: {
                                            width: '90%',
                                        },
                                    })}>
                                        <FormLabel>Notes / Queries</FormLabel>
                                        <Textarea minRows={3} required value={lead.notes}
                                                  inputProps={{style: {textTransform: "uppercase"}}}
                                                  onChange={(event) => {
                                                      setLead(prevLead => ({
                                                          ...prevLead,
                                                          notes: event.target.value.toUpperCase()
                                                      }));
                                                  }} sx={{background: 'white'}}
                                                  placeholder="Notes / Queries"></Textarea>

                                        {!validPostcode &&
                                            <FormHelperText>
                                                <Typography color="danger">Please enter a valid postcode!</Typography>
                                            </FormHelperText>
                                        }

                                    </Box>

                                    <Box sx={(theme) => ({
                                        width: '100%', margin: '10px',
                                        [theme.breakpoints.down('md')]: {
                                            width: '90%',
                                        },
                                    })}>
                                        <Button type="submit" sx={{width: '100%'}}
                                                endDecorator={<TrendingFlat/>}>Send Message</Button>

                                    </Box>
                                    <Box sx={(theme) => ({
                                        marginTop: '25px',
                                        alignItems: 'center',
                                        display: 'flex',
                                        width: '100%',
                                        justifyContent: 'center',
                                        [theme.breakpoints.down('md')]: {
                                            width: '70%',
                                            minWidth: '200px'
                                        },
                                    })}>
                                        <img style={{width: '90%', minWidth: '250px'}}
                                             src={trustPilotVert}
                                             alt="Sky High Window Badges & Certificates"/>
                                    </Box>
                                </Box>
                            </>
                        )}

                    </form>

                </Sheet>

                <Sheet sx={(theme) => ({
                    padding: '50px', [theme.breakpoints.down('md')]: {
                        padding: '0px',
                        margin: '15px'
                    },
                })}>
                    <Typography level="h1" color={"primary"}
                                sx={{textAlign: 'center', marginTop: '30px', marginBottom: '30px'}}>Serving Clients For
                        Over 17
                        Years</Typography>
                    <Box sx={{
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginBottom: '30px'
                    }}>
                        <Grid container spacing={2}
                              sx={(theme) => ({
                                  alignItems: "center",
                                  justifyContent: 'center',
                                  width: '70%',
                                  [theme.breakpoints.down('md')]: {
                                      width: '100%'
                                  }
                              })}>
                            {logos.map((image, index) => (
                                <Grid item md={2} sm={2} xs={2} key={index}>
                                    <Box
                                        component="img"
                                        src={image}
                                        alt={`Image ${index + 1}`}
                                        sx={{width: '100%', height: 'auto', borderRadius: '8px'}}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    </Box>
                    <Typography sx={(theme) => ({
                        width: '70%', textAlign: 'center', margin: 'auto', [theme.breakpoints.down('md')]: {
                            width: '100%'
                        }
                    })}
                                color={"neutral"}>
                        For over 17 years, our commercial window and gutter cleaning company has been proudly serving
                        businesses across the UK. With a commitment to quality, reliability, and safety, we have built
                        lasting relationships with our clients by delivering top-notch cleaning services tailored to
                        their specific needs. Trusted by businesses nationwide, we continue to
                        set the standard in commercial cleaning, ensuring every project is completed to the highest
                        standards.</Typography>
                </Sheet>
                <Box sx={(theme) => ({
                    padding: '50px',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column'
                    , [theme.breakpoints.down('md')]: {
                        padding: '0px',
                        margin: '15px',
                        width: 'fit-content'
                    }
                })}>
                    <Box
                        sx={(theme) => ({
                            width: '70%',
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            marginTop: '35px'
                            , [theme.breakpoints.down('md')]: {
                                width: '100%',
                                justifyContent: 'center',
                                flexDirection: 'column'
                            }
                        })}>
                        <Box
                            component="img"
                            src={bns}
                            sx={(theme) => ({
                                width: '15vw',
                                maxWidth: '180px',
                                height: 'auto',
                                borderRadius: '8px',
                                [theme.breakpoints.down('md')]: {
                                    margin: '22px',
                                    minWidth: '125px',
                                }
                            })}
                        />
                        <Box sx={(theme) => ({
                            maxWidth: '60%', [theme.breakpoints.down('md')]: {
                                maxWidth: '100%',
                                textAlign: 'center',
                                width: '100%',
                            }
                        })}>
                            <Typography>"I just wanted to say a big thank you for all the work you do for the sites I
                                manage."</Typography>
                            <Typography sx={{marginTop: '10px'}}>"You are always friendly and helpful and will go above
                                and beyond to provide
                                excellent service."</Typography>
                            <Typography sx={{fontWeight: '500', marginTop: '25px'}}>Lydia Patel, BNS Property
                                Management</Typography>
                        </Box>
                    </Box>
                    <Box
                        sx={(theme) => ({
                            width: '70%',
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            marginTop: '35px',
                            [theme.breakpoints.down('md')]: {
                                width: '100%',
                                justifyContent: 'center',
                                flexDirection: 'column'
                            }

                        })}>
                        <Box
                            component="img"
                            src={lsh}
                            sx={(theme) => ({
                                width: '15vw', maxWidth: '180px',
                                height: 'auto', borderRadius: '8px',
                                [theme.breakpoints.down('md')]: {
                                    margin: '22px',
                                    minWidth: '125px',

                                }
                            })}
                        />
                        <Box sx={(theme) => ({
                            maxWidth: '60%', [theme.breakpoints.down('md')]: {
                                maxWidth: '100%',
                                textAlign: 'center',
                                width: '100%'
                            }
                        })}>
                            <Typography>"I recently hired Sky High for a full window cleaning and gutter clearance
                                service, and I couldn't be happier with the results. From the moment I reached out,
                                their team was professional, responsive, and friendly. Scheduling the appointment was
                                quick and easy, and they were very accommodating to my preferred time."</Typography>
                            <Typography sx={{fontWeight: '500', marginTop: '25px'}}>Grace Knight, Lambert Smith Hampton
                                Residential</Typography>
                        </Box>
                    </Box>
                </Box>

                <Box sx={(theme) => ({
                    width: '85%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    marginLeft: 'auto',
                    padding: '50px',
                    marginRight: 'auto',
                    [theme.breakpoints.down('md')]: {
                        flexDirection: 'column',
                        padding: '15px',
                        width: '100%',
                        textAlign: 'center',
                        boxSizing: 'border-box'
                    }
                })}>
                    <Box sx={(theme) => ({
                        width: '50%', [theme.breakpoints.down('md')]: {
                            width: '100%',
                        },
                    })}>
                        <Typography level="h1" color={"primary"}
                                    sx={{marginTop: '24px', marginBottom: '30px', textAlign: 'center'}}>Certified &
                            Accredited</Typography>

                        <Typography>
                            At Sky High, we are a certified and accredited commercial cleaning service specialising in
                            gutter, roof, and window cleaning. Our accreditation ensures that we adhere to industry
                            standards for safety, quality, and environmental care, with our technicians trained in
                            advanced cleaning techniques and proper equipment usage.
                        </Typography>
                        <Typography sx={{marginTop: '10px'}}>
                            By meeting these rigorous standards, we guarantee efficient, damage-free services that
                            maintain the integrity of your property. With our certification, Sky High delivers
                            professional, high-quality cleaning that complies with all regulatory requirements, ensuring
                            customer satisfaction and long-term property protection.
                        </Typography>
                    </Box>
                    <Box sx={{width: '50%', display: 'flex', justifyContent: 'center'}}>
                        <Grid container spacing={2} sx={(theme) => ({
                            alignItems: "center",
                            justifyContent: 'center',
                            width: '60%',
                            [theme.breakpoints.down('md')]: {
                                width: '100%',
                                maxWidth: '350px',
                                marginTop: '25px'
                            }
                        })}>
                            {certificates.slice(0, 6).map((image, index) => (
                                <Grid item md={8} sm={5} key={index}>
                                    <Box
                                        component="img"
                                        src={image}
                                        alt={`Image ${index + 1}`}
                                        sx={{width: '100%', height: 'auto', borderRadius: '8px'}}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    </Box>
                </Box>

                {/* <Box sx={(theme) => ({
                    width: '85%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    padding: '50px'
                    , [theme.breakpoints.down('md')]: {
                        padding: '0px'
                    }


                })}>

                    <Typography level="h1" color={"primary"}
                                sx={{marginTop: '24px', marginBottom: '30px', textAlign: 'center'}}>Our
                        Services</Typography>

                    <Box sx={{display: 'flex', flexWrap: 'wrap'}}>
                        <Card variant="outlined" sx={{display: 'flex', flexBasis: '40%', width: 320, m: 2}}>
                            <CardOverflow>
                                <AspectRatio ratio="2">
                                    <img
                                        src="https://images.unsplash.com/photo-1532614338840-ab30cf10ed36?auto=format&fit=crop&w=318"
                                        srcSet="https://images.unsplash.com/photo-1532614338840-ab30cf10ed36?auto=format&fit=crop&w=318&dpr=2 2x"
                                        loading="lazy"
                                        alt=""
                                    />
                                </AspectRatio>
                            </CardOverflow>
                            <CardContent>
                                <Typography level="title-md">Window Cleaning</Typography>
                                <Typography level="title-sm" color={"neutral"}>Window cleaning involves removing dirt,
                                    grime, and streaks from glass surfaces using water, detergents, and specialised
                                    tools. It enhances visibility and appearance while protecting windows from long-term
                                    damage.</Typography>
                            </CardContent>
                        </Card>

                        <Card variant="outlined" sx={{display: 'flex', flexBasis: '40%', width: 320, m: 2}}>
                            <CardOverflow>
                                <AspectRatio ratio="2">
                                    <img
                                        src="https://images.unsplash.com/photo-1532614338840-ab30cf10ed36?auto=format&fit=crop&w=318"
                                        srcSet="https://images.unsplash.com/photo-1532614338840-ab30cf10ed36?auto=format&fit=crop&w=318&dpr=2 2x"
                                        loading="lazy"
                                        alt=""
                                    />
                                </AspectRatio>
                            </CardOverflow>
                            <CardContent>
                                <Typography level="title-md">Gutter Cleaning</Typography>
                                <Typography level="title-sm" color={"neutral"}>Gutter cleaning involves removing leaves,
                                    debris, and blockages from gutters to ensure proper water flow and prevent damage to
                                    a building's foundation, roof, and walls. Regular cleaning helps avoid clogs, leaks,
                                    and water-related issues.</Typography>
                            </CardContent>
                        </Card>
                        <Card variant="outlined" sx={{display: 'flex', flexBasis: '40%', width: 320, m: 2}}>
                            <CardOverflow>
                                <AspectRatio ratio="2">
                                    <img
                                        src="https://images.unsplash.com/photo-1532614338840-ab30cf10ed36?auto=format&fit=crop&w=318"
                                        srcSet="https://images.unsplash.com/photo-1532614338840-ab30cf10ed36?auto=format&fit=crop&w=318&dpr=2 2x"
                                        loading="lazy"
                                        alt=""
                                    />
                                </AspectRatio>
                            </CardOverflow>
                            <CardContent>
                                <Typography level="title-md">Roof Cleaning</Typography>
                                <Typography level="title-sm" color={"neutral"}>Roof cleaning involves removing dirt,
                                    algae, moss, lichen, and other debris from a roof's surface to prevent damage and
                                    improve its appearance. Regular roof
                                    cleaning helps extend the lifespan of the roof and maintains its structural
                                    integrity.</Typography>
                            </CardContent>
                        </Card>
                        <Card variant="outlined" sx={{display: 'flex', flexBasis: '40%', width: 320, m: 2}}>
                            <CardOverflow>
                                <AspectRatio ratio="2">
                                    <img
                                        src="https://images.unsplash.com/photo-1532614338840-ab30cf10ed36?auto=format&fit=crop&w=318"
                                        srcSet="https://images.unsplash.com/photo-1532614338840-ab30cf10ed36?auto=format&fit=crop&w=318&dpr=2 2x"
                                        loading="lazy"
                                        alt=""
                                    />
                                </AspectRatio>
                            </CardOverflow>
                            <CardContent>
                                <Typography level="title-md">Power Wash</Typography>
                                <Typography level="title-sm" color={"neutral"}>Power washing commercially involves using
                                    high-pressure water jets to clean large surfaces, such as building exteriors. It's
                                    commonly used by businesses to maintain cleanliness, remove grime, graffiti, and
                                    other contaminants.</Typography>
                            </CardContent>
                        </Card>
                    </Box>

                </Box>*/}

                <Box sx={(theme) => ({
                    width: '85%',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    padding: '50px',
                    justifyContent: 'space-between'
                    , [theme.breakpoints.down('md')]: {
                        padding: '15px',
                        boxSizing: 'border-box',
                        flexDirection: 'column-reverse',
                        width: '100%'
                    }


                })}>
                    <Box sx={(theme) => ({
                        width: '45%',
                        maxWidth: '500px',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        [theme.breakpoints.down('md')]: {
                            width: '60%',
                            marginTop: '25px',
                            maxWidth: '400px',
                        }
                    })}>
                        <Box
                            component="img"
                            src={ukMap}
                            sx={{width: '100%', height: 'auto', borderRadius: '8px'}}
                        />
                    </Box>

                    <Box sx={(theme) => ({
                        width: '45%', [theme.breakpoints.down('md')]: {
                            width: '100%',
                            textAlign: 'center'
                        }
                    })}>
                        <Typography level="h1" color={"primary"}
                                    sx={{marginTop: '24px', marginBottom: '30px'}}>Nationwide
                            Coverage</Typography>
                        <Typography>At Sky High, we offer nationwide coverage, ensuring that our high-quality gutter,
                            roof, and window cleaning services are accessible to customers across the country. Our
                            extensive network of trained technicians allows us to respond quickly and efficiently, no
                            matter where your property is located.</Typography>

                        <Typography sx={{marginTop: '10px'}}>This national reach enables us to maintain consistent
                            service standards and deliver exceptional results for all our clients. Whether you're a
                            single-location business or a multi-site operation, Sky High is committed to providing
                            reliable cleaning solutions tailored to your needs, no matter the distance.</Typography>
                    </Box>


                </Box>

                <Box sx={(theme) => ({
                    height: '500px', marginTop: '50px', [theme.breakpoints.down('md')]: {
                        padding: '0px'
                    }
                })}>
                    <Card sx={(theme) => ({
                        display: 'flex',
                        flexDirection: 'row',
                        maxWidth: '70%',
                        justifyContent: 'space-between',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        marginBottom: '-75px',
                        zIndex: 100000,
                        [theme.breakpoints.down('md')]: {
                            maxWidth: '90%',
                            flexDirection: 'column',
                            marginBottom: '-111px'
                            , boxSizing: 'border-box'
                        }
                    })}>
                        <Box sx={(theme) => ({
                            width: '45%',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            [theme.breakpoints.down('md')]: {width: '100%'}
                        })}>
                            <Typography level="h3" color={"primary"}
                                        sx={{margin: 0, textAlign: 'left'}}>Get in Touch</Typography>
                            <Typography level="body-md" color={"neutral"}
                                        sx={{margin: 0}}>We're ready and waiting to take
                                your call. If you would prefer to submit a message request form, click the 'Get A Quote'
                                button to the right.</Typography>
                        </Box>
                        <Box sx={(theme) => ({
                            width: '45%',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            [theme.breakpoints.down('md')]: {width: '100%'}

                        })}>
                            <Button onClick={(event) => {
                                scrollToForm()
                            }} sx={(theme) => ({
                                paddingLeft: '50px',
                                paddingRight: '50px',
                                [theme.breakpoints.down('md')]: {width: '100%', whiteSpace: 'nowrap'}
                            })} size={"lg"}>Click Here To
                                Get A
                                Quote</Button>
                            <Sheet variant={"outlined"} sx={(theme) => ({
                                textAlign: 'center',
                                paddingLeft: '92px',
                                paddingRight: '92px',
                                paddingTop: '8px',
                                paddingBottom: '8px',
                                marginTop: '5px',
                                borderRadius: '5px',
                                [theme.breakpoints.down('md')]: {
                                    width: '100%',
                                    boxSizing: 'border-box',
                                    whiteSpace: 'nowrap',
                                    paddingLeft: '0',
                                    paddingRight: '0',
                                }
                            })}><Typography level={"title-md"}>
                                07914
                                456 742
                            </Typography></Sheet>
                        </Box>
                    </Card>
                    <Sheet color="neutral" variant="solid" sx={(theme) => ({
                        width: '100%', height: '400px', [theme.breakpoints.down('md')]: {
                            height: '120%'
                        }
                    })}>
                        <Box sx={(theme) => ({
                            width: '70%',
                            marginLeft: 'auto',
                            marginRight: 'auto',
                            display: 'flex',
                            alignItems: 'center',
                            height: '100%',
                            justifyContent: 'space-between',
                            [theme.breakpoints.down('md')]: {
                                flexDirection: 'column',
                                width: '100%',
                                justifyContent: 'normal',
                                overflow: 'hidden'
                            }
                        })}>
                            <Box sx={(theme) => ({
                                marginTop: '50px',
                                width: '30%',
                                [theme.breakpoints.down('md')]: {
                                    marginTop: '150px',
                                    width: 'fit-content',
                                    marginLeft: 'auto',
                                    marginRight: 'auto'
                                }
                            })}>
                                <img style={{"height": "100px"}} src={logo_white} alt="Sky High Window Cleaning Logo"/>
                            </Box>

                            <Box sx={(theme) => ({
                                marginTop: '50px',
                                width: '60%',
                                [theme.breakpoints.down('md')]: {
                                    width: '80%',
                                }
                            })}>
                                <Typography sx={{color: "white"}}>Sky High Window Cleaning is a certified and accredited
                                    commercial cleaning service specialising in the meticulous cleaning of windows,
                                    roofs, and gutters. Committed to delivering exceptional results, Sky High employs
                                    advanced techniques and equipment to ensure that both residential and commercial
                                    properties shine.
                                </Typography>
                                <Box sx={{marginTop: '15px'}}>
                                    <Typography startDecorator={<Phone/>} level="body-md"
                                                sx={{color: "white", marginBottom: '5px'}}>07914
                                        456
                                        742</Typography>
                                    <Typography level="body-md" startDecorator={<Email/>}
                                                sx={{color: "white"}}>info@skyhighwindowcleaning.com</Typography>
                                </Box>

                            </Box>
                        </Box>

                    </Sheet>
                </Box>
            </Box>
        </>
    )

}