import * as React from "react";
import logo from './images/logo.jpg';
import trust_pilot from './images/trust_pilot.jpg';
import {Box, Button, Link, Tooltip, Typography} from "@mui/joy";
import {KeyboardArrowRight} from "@mui/icons-material";

export default function NavigationBar({selectedService}) {


    return (
        <header style={{'width': '100%'}}>
            <Box sx={(theme) => ({
                display: 'flex', justifyContent: 'space-between', width: '100%', height: '100%',
                [theme.breakpoints.down('md')]: {
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '80%',
                    marginLeft: 'auto',
                    marginRight: 'auto'
                }
            })}>
                <Box sx={{display: 'flex', alignItems: 'center', height: '100%'}}>
                    <img style={{"height": "50px"}} src={logo} alt="Sky High Window Cleaning Logo"/>
                    <img style={{"height": "50px", marginLeft: '15px'}} src={trust_pilot}
                         alt="Trust pilot review score for Sky High Window Cleaning"/>
                </Box>
                <Box sx={(theme) => ({
                    display: 'flex', justifyContent: 'space-between', flexDirection: 'column',
                    [theme.breakpoints.down('md')]: {
                        display: 'none',
                    }
                })}>
                    <Box sx={(theme) => ({
                        display: 'flex', alignItems: 'center', justifyContent: 'end',
                        [theme.breakpoints.down('md')]: {
                            marginTop: '10px',
                            flexDirection: 'column',
                            textAlign: 'center',
                        }
                    })}>
                        <Typography sx={(theme) => ({
                            marginRight: '15px',
                            fontWeight: '5000',
                            [theme.breakpoints.down('md')]: {
                                marginRight: '0px',
                                marginBottom: '5px'
                            }
                        })} color="primary"
                                    level="title-lg">Call
                            us
                            on: <Typography color="primary" sx={{fontWeight: '750'}}
                                            level="title-lg">07415 341279</Typography></Typography>
                        <Button sx={(theme) => ({[theme.breakpoints.down('md')]: {textWrap: 'nowrap'}})}
                                endDecorator={<KeyboardArrowRight/>}>Get An Instant
                            Quote</Button>
                    </Box>
                    <Box sx={{display: 'flex', justifyContent: 'end', alignItems: 'center', marginTop: '10px'}}>
                        <Link href="/window-clean" sx={{fontWeight: '500'}}
                              underline={selectedService.trim().toLowerCase() === 'window clean'.toLowerCase() ? 'always' : 'none'}>
                            Window Cleaning
                        </Link>
                        <Typography color="primary"
                                    sx={{fontWeight: '1000', marginLeft: '15px', marginRight: '15px'}}
                                    level="title-lg">/</Typography>
                        <Link href="/gutter-clean" sx={{fontWeight: '500'}}
                              underline={selectedService.trim().toLowerCase() === 'gutter clean'.toLowerCase() ? 'always' : 'none'}>
                            Gutter Cleaning
                        </Link>
                        <Typography color="primary"
                                    sx={{fontWeight: '1000', marginLeft: '15px', marginRight: '15px'}}
                                    level="title-lg">/</Typography>
                        <Tooltip title="Coming Soon!" color="primary" arrow placement="right">
                            <Box>
                                <Link sx={{fontWeight: '500'}} disabled={true}>Roof Cleaning</Link>
                            </Box>
                        </Tooltip>
                    </Box>
                </Box>
            </Box>
        </header>
    )

}